@tailwind components;

@layer components {
  .arrow-down-execution {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      content: '';
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid #f7bc46;
    }
  }
}
