.arrow-up {
  width: 0;
  height: 0;
  border-left: 24px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 43px solid #dc2626;
  position: relative;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 21px solid transparent;
    border-right: 23px solid transparent;
    border-bottom: 39px solid #18191e;
    position: absolute;
    top: 4px;
    left: -21px;
  }
}

.switch-show-plans {
  border: 2px solid #dc2626;
}

ion-modal#riskAssessmentModal {
  --height: 540px !important;
  --width: 94%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  ion-modal#riskAssessmentModal {
    --width: 500px;
  }
}

ion-modal#riskAssessmentModal::part(scroll) {
  background: #000 !important;
}

ion-modal#riskAssessmentModal::part(backdrop) {
  background: #000 !important;
  opacity: 1;
}

ion-modal#riskAssessmentModal ion-toolbar {
  --background: #000;
  --color: white;
}

ion-modal#riskAssessmentModal ion-content {
  //   border: 3px solid red;
  --background: #000 !important;
  --color: white;
}
