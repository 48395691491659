:root {
    --ion-color-rose: #0c0c0c;
    --ion-color-rose-rgb: 17, 17, 17;
    --ion-color-rose-contrast: #ffffff;
    --ion-color-rose-contrast-rgb: 0, 0, 0;
    --ion-color-rose-shade: #111111;
    --ion-color-rose-tint: #111111;
  }
  
  .ion-color-black {
    --ion-color-base: var(--ion-color-rose);
    --ion-color-base-rgb: var(--ion-color-rose-rgb);
    --ion-color-contrast: var(--ion-color-rose-contrast);
    --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
    --ion-color-shade: var(--ion-color-rose-shade);
    --ion-color-tint: var(--ion-color-rose-tint);
  }
  
  div[slot='content'] {
    background: rgba(var(--ion-color-rose-rgb), 1);
  }