.approval-modal {
  &::part(content) {
    height: 430px;
  }
}

.risk-assessment-modal {
  &::part(content) {
    min-height: 650px;
  }
}

.alert-wrapper {
  background: #c6c6c6 !important;

  .alert-title {
    color: #000;
  }

  .alert-radio-label,
  .alert-radio-icon {
    color: #2e2d2d;
  }
}

ion-modal.approval-modal {
  --height: 340px;
  --width: 94%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  ion-modal.approval-modal {
    --width: 500px;
  }
}

ion-modal.approval-modal::part(scroll) {
  background: #000 !important;
}

ion-modal.approval-modal::part(background) {
  --background: #fff !important;
}

ion-modal.approval-modal::part(backdrop) {
  background: #000 !important;
  opacity: 1;
}

ion-modal.approval-modal ion-toolbar {
  --background: #000;
  --color: white;
}

ion-modal.approval-modal ion-content {
  --background: #fff !important;
  --color: white;
}

.dark .approval-modal ion-content {
  background: #fff !important;
  --background: #fff !important;
}

ion-modal#planTypeSelectionModal {
  --height: 100%;
  --width: 100%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  ion-modal#planTypeSelectionModal {
    --width: 605px;
    --height: 605px;
  }
}

ion-modal#planTypeSelectionModal::part(scroll) {
  background: #000 !important;
}

ion-modal#planTypeSelectionModal::part(backdrop) {
  background: #000 !important;
  opacity: 1;
}

ion-modal#planTypeSelectionModal .ion-page {
  justify-content: flex-start;
  background: #000;

  ion-item {
    --background: #18191e !important;
  }
}

ion-modal#planTypeSelectionModal ion-toolbar {
  --background: #000;
  --color: white;
}

ion-modal#planTypeSelectionModal ion-content {
  --background: #000 !important;
  --color: white;
}
