.dark {
  ion-datetime {
    // --color: #0f9;
    --color: #1d1d1b !important;
  }

  ion-datetime::part(native) {
    color: #9aa2ae;

    .sc-ion-label-ios-h {
      --color: #1d1d1b !important;
      color: #1d1d1b !important;
    }
  }
}
