@tailwind components;

@layer components {
  .arrow-down-decision {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      content: '';
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid #3cbfbc;
    }
  }
}

.arrow-down-uncheck {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    content: '';
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid #c3c3c3;
  }
}

.border-top-decision-plan::before {
  content: '';
  position: absolute;
  left: 0;
  top: -7px;
  width: 100%;
  height: 7px;
  background: linear-gradient(to right, #83c9c5, #3fa3d1);
}
