.comment-pagination-dashboard {
  text-align: center;
  margin-top: -10px;
  width: 100%;
  padding-bottom: 20px;

  li {
    position: relative;
    padding-top: 3px;
    display: inline-block;
    background: #fff;
    border-radius: 90%;
    width: 30px;
    height: 30px;
    color: #3c3c3c;

    &:first-child {
      margin-right: 7px;
      color: #f42f55;
    }

    &:last-child {
      margin-left: 7px;
      color: #f42f55;
    }

    &:not(:first-child):not(:last-child) {
      margin-left: 7px;
      margin-right: 7px;
    }

    &.selected {
      background: #f42f55;
    }
  }
}

.dark {
  .comment-pagination-dashboard {
    .selected {
      background: #f42f55;
      color: #fff;
    }
  }
}

.arrow_box {
  position: relative;
  background: #000;
  border: 2px solid #f42f55;
  top: -3px;
}
.arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #000;
  border-width: 7px;
  margin-left: -7px;
}
.arrow_box:before {
  border-color: rgba(244, 47, 85, 0);
  border-top-color: #f42f55;
  border-width: 11px;
  margin-left: -11px;
}

.arrow_right_box {
  position: relative;
  background: #000;
  border: 2px solid #f42f55;
}
.arrow_right_box:after,
.arrow_right_box:before {
  left: 100%;
  top: 75%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_right_box:after {
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #000;
  border-width: 11px;
  margin-top: -11px;
}
.arrow_right_box:before {
  border-color: rgba(244, 47, 85, 0);
  border-left-color: #f42f55;
  border-width: 15px;
  margin-top: -15px;
}
