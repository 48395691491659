.comment-pagination {
  text-align: center;
  margin-top: 15px;
  width: 100%;
  li {
    display: inline;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 10px;
    }

    &:not(:first-child):not(:last-child) {
      margin-left: 5px;
      margin-right: 5px;
      padding: 5px 10px;
      border-radius: 5px;
    }

    &.selected {
      background: #e5e7eb;
    }
  }
}

.dark {
  .comment-pagination {
    .selected {
      background: #fff;
      color: #101827;
    }
  }
}
