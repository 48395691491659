.switch-holder {
  display: flex;
  width: 250px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}
.switch-label {
  color: rgb(107, 114, 128);
}
.switch-label i {
  margin-right: 5px;
}
.switch-toggle {
  height: 40px;
}
.switch-toggle input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  z-index: -2;
}
.switch-toggle input[type='checkbox'] + label {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  margin: 0;
  cursor: pointer;
  //   box-shadow: inset -8px -8px 15px rgba(255, 255, 255, 0.6), inset 10px 10px 10px rgba(0, 0, 0, 0.25);
  background: #cac8c8;
}
.switch-toggle input[type='checkbox'] + label::before {
  position: absolute;
  content: 'OFF';
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  top: 8px;
  left: 8px;
  width: 45px;
  height: 25px;
  border-radius: 20px;
  background-color: #eeeeee;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-in-out;
}
.switch-toggle input[type='checkbox']:checked + label::before {
  left: 50%;
  content: 'ON';
  color: #ffffff;
  background-color: #1d4ed8;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  //   box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.5), 3px 3px 5px #00b33c;
}

.dark {
  .switch-toggle input[type='checkbox']:checked + label::before {
    background-color: #009cde;
  }
}
