.class-icon-greeting {
  ion-icon {
    font-size: 38px;
    fill: #ed664c;
  }
}

.class-icon-inspec-plan {
  &.expanded {
    ion-icon {
      margin-left: -9px;
    }
  }

  ion-icon {
    font-size: 55px;
    fill: #e24d5b;
  }
}

.class-icon-decision {
  ion-icon {
    font-size: 38px;
    fill: #3cbfbc;
  }
}

.class-icon-execution {
  ion-icon {
    font-size: 38px;
    fill: #f7bc46;
  }
}

.class-icon-report {
  ion-icon {
    font-size: 38px;
    fill: #f72d6f;
  }
}

.selected-greeting {
  border-left: 7px solid #ed664c !important;
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -10px;
    height: 63px;
    width: 10px;
    background: #000;
  }
}

.selected-inspec-plan {
  border-left: 7px solid #e24d5b !important;
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -10px;
    height: 80px;
    width: 10px;
    background: #000;
  }
}

.selected-decision {
  border-left: 7px solid #3cbfbc !important;
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -10px;
    height: 63px;
    width: 10px;
    background: #000;
  }
}

.selected-execution {
  border-left: 7px solid #f7bc46 !important;
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -10px;
    height: 63px;
    width: 10px;
    background: #000;
  }
}

.selected-report {
  border-left: 7px solid #f72d6f !important;
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -10px;
    height: 63px;
    width: 10px;
    background: #000;
  }
}

.selected-border-Greeting {
  border-color: #ed664c !important;
}

.selected-border-Inspection {
  border-color: #e24d5b !important;
}

.selected-border-plan-decision {
  border-color: #3cbfbc !important;
}

.selected-border-Execution {
  border-color: #f7bc46 !important;
}

.selected-border-Report {
  border-color: #f72d6f !important;
}

.workcard-item {
  border-left: 7px solid transparent;
}
