.border-top-inspection-plan::before {
  content: '';
  position: absolute;
  left: 0;
  top: -7px;
  width: 100%;
  height: 7px;
  background: #c31956;
}

.icon-inspec-plan-section {
  ion-icon {
    font-size: 64px;
  }
}
