ion-datetime-button::part(native) {
  color: #fff;
  height: 26px;
  font-size: 20px;
  padding-left: 0;
  padding-right: 0;
  padding: 0;
  margin-top: 4px;
  // background: #eee;
}

.date-filter {
  ion-datetime-button::part(native) {
    font-size: 20px !important;
  }
}

.dark {
  ion-datetime-button::part(native) {
    background: transparent;
    color: #fff;
  }
}

@media (max-width: 1110px) {
  ion-datetime-button::part(native) {
    font-size: 13px;
  }
}

@media (max-width: 850px) {
  ion-datetime-button::part(native) {
    font-size: 13px;
  }
}

@media (max-width: 700px) {
  ion-datetime-button::part(native) {
    font-size: 10px;
  }
}

calendar-action-buttons::part(native) {
  color: #1d1d1b !important;
  --color: #1d1d1b !important;
}

ion-datetime {
  --placeholder-color: #1d1d1b;

  --background: #c6c6c6;
  --background-rgb: rgb(198, 198, 198);
  --ion-color-base: #1d1d1b;
  --ion-color-base-rgb: 29, 29, 27;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1d1d1b;
  --ion-color-tint: #1d1d1b;
  --color: #1d1d1b;
  --ion-text-color: #1d1d1b;

  &:not(.datetime-placeholder) {
    color: #000;
  }
}
