@tailwind components;

@layer components {
  .arrow-down-report {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      content: '';
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid #f72d6f;
    }
  }
}

@layer components {
  .arrow-down-redbox {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      content: '';
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid #f42f55;
    }
  }
}

ion-modal#redboxComment {
  --height: 540px;
  --width: 94%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  ion-modal#redboxComment {
    --width: 500px;
  }
}

ion-modal#redboxComment::part(scroll) {
  background: #000 !important;
}

ion-modal#redboxComment::part(backdrop) {
  background: #000 !important;
  opacity: 1;
}

ion-modal#redboxComment ion-toolbar {
  --background: #000;
  --color: white;
}

ion-modal#redboxComment ion-content {
  //   border: 3px solid red;
  --background: #000 !important;
  --color: white;
}

.switch-approve-comment {
  border: 2px solid #f42f55;
}
