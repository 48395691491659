.slide-to-confirm {
  height: 4rem;
  width: auto;
  border-radius: 6rem;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.2rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  touch-action: none;

  .toggle {
    width: 3.3rem;
    height: 3.3rem;
    position: absolute;
    left: 0.7rem;
    background: #000;
    border-radius: 3rem;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.circle-loader {
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  border: 0.25rem solid #e9e9e9;
  border-top: 0.25rem solid #000;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 19px;
  left: 60%;
}
