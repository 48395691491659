@tailwind components;

@layer components {
  .arrow-down-inspection {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      content: '';
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid #e24d5b;
    }
  }
}

.half-circle-left {
  border-radius: 33px 0px 0px 33px;
}

.half-circle-right {
  border-radius: 0px 33px 33px 0px;
}
