ion-segment-button {
  --border-radius: 20px;
}

ion-segment-button {
  --indicator-color: #1d4ed8;
}

ion-segment-button {
  --color-checked: #fff;
  --border-radius: 20px;
}

.dark {
  ion-segment-button {
    --indicator-color: #009cde;
  }
}
