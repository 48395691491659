.dark {
  main {
    background-color: #101827 !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  visibility: hidden !important;
  width: 7px !important;
}

*:hover::-webkit-scrollbar {
  height: 7px !important;
  visibility: visible !important;
  background-color: rgb(145, 145, 145) !important;
}

.workcard-header {
  -webkit-backface-visibility: hidden;
}

/* chrome, safari */
ion-content::part(scroll)::-webkit-scrollbar {
  visibility: hidden !important;
  width: 7px !important;
}

ion-content::part(scroll):hover::-webkit-scrollbar {
  height: 7px !important;
  visibility: visible !important;
  background-color: rgb(145, 145, 145) !important;
}

ion-content::part(scroll)::-webkit-scrollbar {
  display: none !important;
}
