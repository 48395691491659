.inspection-checklist-modal {
  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    position: relative;
    overflow: auto;
  }
}
