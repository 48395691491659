.error-no-selected-user {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    bottom: 0px;
    background: red;
  }
}
