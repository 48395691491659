@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-out-save {
  animation: fadeInOut 2s;
}

.save-indicator {
  opacity: 0;
}
