.refresher-refreshing {
  margin-top: 32px;

  ion-spinner {
    color: #fff !important;
  }

  .arrow-container ion-icon {
    color: #fff !important;
  }
}

.ion-refresher-workcard {
  margin-left: 40px;
  margin-top: 67px;
  z-index: 1;

  &.margin-sidebar-open {
    margin-left: 140px;
  }
}

@media (max-width: 850px) {
  .ion-refresher-workcard {
    margin-left: 0px !important;
    margin-top: 50px;
  }
}

.ion-refresher-dashboard {
  margin-left: 40px;
  margin-top: 135px;
  z-index: 1;

  &.margin-sidebar-open {
    margin-left: 140px;
  }
}

@media (max-width: 850px) {
  .ion-refresher-dashboard {
    margin-left: 0px !important;
    margin-top: 135px;
  }
}
