@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?p824ue');
  src:  url('fonts/icomoon.eot?p824ue#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?p824ue') format('truetype'),
    url('fonts/icomoon.woff?p824ue') format('woff'),
    url('fonts/icomoon.svg?p824ue#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-worker:before {
  content: "\e90c";
}
.icon-decision:before {
  content: "\e900";
}
.icon-execution:before {
  content: "\e901";
}
.icon-greeting:before {
  content: "\e902";
}
.icon-inspection:before {
  content: "\e903";
}
.icon-inspection-planif:before {
  content: "\e904";
}
.icon-planification:before {
  content: "\e905";
}
.icon-RedBox:before {
  content: "\e906";
}
.icon-report:before {
  content: "\e907";
}
.icon-supervisor:before {
  content: "\e908";
}
.icon-wifi-intermit:before {
  content: "\e909";
}
.icon-wifi-off:before {
  content: "\e90a";
}
.icon-wifi-ok:before {
  content: "\e90b";
}
