ul.approval-info-modal {
  list-style: none;
  margin-left: 10px;
  padding: 0;
  font-size: 1.2rem;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-weight: bold;

    span:first-child {
      color: #a8a8a8;
      font-weight: normal;
    }
  }
}
