ion-tabs {
  contain: initial !important;
}

ion-tab-bar {
  height: 57px !important;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10000000;

  ion-icon {
    margin-top: 6px;
  }

  ion-label {
    height: 20px;
    line-height: 1;
    margin-top: 3px;
    margin-bottom: 2px;
  }
}

ion-tab-button {
  position: relative;
  --color: var(--ion-color-medium);
  // --color-selected: var(--ion-color-primary);

  &::before {
    background-color: transparent;
    display: block;
    content: '';
    margin: 0 auto;
    width: 100%;
    height: 6px;
    position: absolute;
    bottom: 0;
  }

  &:nth-of-type(1) {
    &::before {
      background-color: #ed664c;
    }

    --color-selected: #ed664c;
  }

  &:nth-of-type(2) {
    &::before {
      background-color: #e24d5b;
    }

    --color-selected: #e24d5b;
  }

  &:nth-of-type(3) {
    &::before {
      background: linear-gradient(to left, #3fa3d1, #3cbfbc);
    }

    --color-selected: #3cbfbc;
  }

  &:nth-of-type(3) {
    &::before {
      background-color: #3cbfbc;
    }

    --color-selected: #3cbfbc;
  }

  &:nth-of-type(4) {
    &::before {
      background-color: #f7bc46;
    }

    --color-selected: #f7bc46;
  }

  &:nth-of-type(5) {
    &::before {
      background-color: #f72d6f;
    }

    --color-selected: #f72d6f;
  }

  // &.tab-selected::before {
  //   background-color: var(--ion-color-primary);
  // }
}
