input {
  padding-left: 10px;
}

textarea {
  padding-left: 10px;
  padding-top: 5px;
}

textarea {
  width: 100%;
  min-height: 50px;
  resize: none;
}
