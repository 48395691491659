/* Core CSS required for Ionic components to work properly */
@import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/react/css/padding.css';
@import '@ionic/react/css/float-elements.css';
@import '@ionic/react/css/text-alignment.css';
@import '@ionic/react/css/text-transformation.css';
@import '@ionic/react/css/flex-utils.css';
@import '@ionic/react/css/display.css';

/* Default theme for Swipe Js */
@import 'swiper/css';
@import 'swiper/css/navigation';

@import 'react-loading-skeleton/dist/skeleton.css';

/* Font Awesome */
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import '@fortawesome/fontawesome-free/css/all.min.css';

/* Theme variables */
@import './variables.css';

// Tailwind styles
@import './tailwind.scss';

// icon-fonts
@import './icon-fonts.scss';

// Custom
@import './customs/main.scss';
@import './customs/input.scss';
@import './customs/ion-datetime-button.scss';
@import './customs/ion-datetime.scss';
@import './customs/ion-accordion.scss';
@import './customs/ion-segment.scss';
@import './customs/ion-item.scss';
@import './customs/ion-refresher.scss';
@import './customs/ion-modal.scss';
@import './customs/ion-slider.scss';
@import './customs/skeleton-theme.scss';
