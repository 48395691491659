.complete-screen {
  text-align: center;
  padding: 1rem 0;
  margin: auto;
  margin-top: 40px;

  svg {
    width: 7rem;
    margin: auto;
  }

  h1 {
    margin-top: 50px;
    color: #000000;
    font-size: 2rem;
  }
}

.dark {
  .complete-screen {
    h1 {
      color: #fff;
    }
  }
}
